import { createReducer, on } from '@ngrx/store'
import * as StageActions from './stage.actions'
import { StageState } from './stage.state'

export const initialState: StageState = {
   user: '',
}

export const stageReducer = createReducer(
   initialState,
   on(StageActions.setUser, (state, action) => {
      return { ...state, user: action.user }
   })
)
