import { HttpClient, provideHttpClient } from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter, withHashLocation } from '@angular/router'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { KeycloakService } from 'keycloak-angular'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { routes } from './app.routes'
import { stageReducer } from './shared/store/stage/stage.reducer'
import { firstValueFrom } from 'rxjs'

export const appConfig: ApplicationConfig = {
   providers: [
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideHttpClient(),
      provideAnimations(),
      provideRouter(routes, withHashLocation()),
      {
         provide: DynamicDialogRef,
         useValue: [],
      },
      TranslateModule.forRoot({
         defaultLanguage: 'en-US',
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
         },
      }).providers!,
      {
         provide: APP_INITIALIZER,
         useFactory: initializeKeycloak,
         multi: true,
         deps: [KeycloakService, HttpClient],
      },
      KeycloakService,
      DialogService,
      importProvidersFrom(
         StoreModule.forRoot({ stage: stageReducer }),
         StoreDevtoolsModule.instrument({
            maxAge: 25,
         })
      ),
   ],
}

// required for AoT
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http)
}

function initializeKeycloak(keycloak: KeycloakService, httpClient: HttpClient) {
   return () => {
      return firstValueFrom(httpClient.get<RuntimeConfig>('assets/configs/properties.json')).then((dc) => {
         return keycloak.init({
            config: {
               url: dc.keycloak.authUrl,
               realm: dc.keycloak.realm,
               clientId: dc.keycloak.clientId,
            },
            initOptions: { enableLogging: true, checkLoginIframe: false, flow: 'standard', onLoad: 'check-sso' },
         })
      })
   }
}

interface RuntimeConfig {
   keycloak: {
      authUrl: string
      clientId: string
      realm: string
   }
}
