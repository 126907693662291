import { Routes } from '@angular/router'
import { AuthenticationService } from './shared/services/authentication.service'

export const routes: Routes = [
   { path: '', redirectTo: 'home', pathMatch: 'full' },
   {
      path: 'home',
      canActivate: [AuthenticationService],
      loadComponent: () => import('./core/components/pages/home/home.component').then((m) => m.HomeComponent),
   },
]
