import { Component, inject, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import packageInfo from '../../package.json'
import { environment } from '../environments/environment'
import { from, filter, catchError, of } from 'rxjs'
import { AuthenticationService } from './shared/services/authentication.service'
import { LogService } from './shared/services/log.service'
import { TranslateService } from '@ngx-translate/core'
import { Store } from '@ngrx/store'
import { KeycloakProfile } from 'keycloak-js'
import { setUser } from './shared/store/stage/stage.actions'
@Component({
   selector: 'matchpoint-root',
   standalone: true,
   imports: [RouterOutlet],
   templateUrl: './app.component.html',
   styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
   private _cn = this.constructor.name
   private _store = inject(Store)
   private _autService = inject(AuthenticationService)

   constructor(
      private _translate: TranslateService,
      private _logService: LogService
   ) {
      this._logService.packageName = packageInfo.name
      this._logService.packageVersion = packageInfo.version
      this._logService.environmentLogLevel = environment.logLevel
   }

   ngOnInit(): void {
      const mn = this.ngOnInit.name
      this._logService.debug(this._cn, mn, 'Selected language:', navigator.language)
      this._translate.use(navigator.language)
      this.setUser()
   }

   private setUser() {
      const mn = this.setUser.name
      from(this._autService.keycloakAngular.loadUserProfile())
         .pipe(
            filter((res) => res != null && res != undefined),
            catchError((error) => {
               this._logService.error(this._cn, mn, 'Cannot retrive user info... logout')
               this._autService.keycloakAngular.logout()
               return of(error)
            })
         )
         .subscribe((user: KeycloakProfile) => {
            this._store.dispatch(setUser({ user: `${user.firstName} ${user.lastName}` }))
         })
   }
}
